$ = jQuery;

var $log = function(msg) {
        if (App.debug) {
            if (arguments.length > 1) {
                var to_console = [];
                for (i = 1; i < arguments.length; i++) {
                    to_console[i - 1] = arguments[i][0];
                }
                console.log(msg, to_console);
            }
            else {
                console.log(msg);
            }
        }
    },
    App = {
        debug: true,
        init: function() {

            this.scripts();

        },
        loaded: function() {

            this.scripts();

        },
        scripts: function() {

            if (App.is_touch_device()) {
                $("body").addClass("touch");
            }
            else {
                $("body").addClass("no-touch");
            }

            // To top
            $(".back-top").on("click", function() {
                $("html,body").animate({
                    scrollTop: 0
                }, 550);
            });

            // Content link
            $(".content-link").click(function(e) {
                if ($(this).attr('data-slug').length && (typeof $(this).data('slug') !== 'undefined')) {
                    e.preventDefault();
                    var id = $(this).attr('data-slug');
                    $('html,body').animate({
                        scrollTop: $('#' + id).offset().top - 50
                    }, 550);
                }
            });

            // Fixed header
            var didScroll,
                lastScrollTop = $(window).scrollTop(),
                delta = 5,
                headerHeight = $('.site-header').outerHeight(),
                winWidth = $(window).width();

            $(".site-header").removeClass("opened");

            if (lastScrollTop > 0) {
                hasScrolled();
                didScroll = false;
            }

            $(window).scroll(function(event) {
                didScroll = true;
            });

            setInterval(function() {
                if (didScroll) {
                    hasScrolled();
                    didScroll = false;
                }
            }, 250);

            function hasScrolled() {
                var st = $(this).scrollTop();
                if (st <= 10) {
                    // Scroll Down
                    $('body').removeClass('page-scrolled');
                    //$(".site-header").addClass("opened");
                }
                else {
                    // Scroll Up
                    //if (st + $(window).height() < $(document).height()) {
                    if (st > 10) {
                        $('body').addClass('page-scrolled');
                        //$(".site-header").removeClass("opened");
                    }
                }

            }

        },

        mobMenu: function(menuWrapper) {

            $this = menuWrapper;

            /* toggle main menu nav */
            $(".menu-btn", $this).on("click", function() {

                menuWrapper.toggleClass("opened");
                $("body").toggleClass("opened");

            });

            $(".main-container").on("click", function() {

                menuWrapper.removeClass("opened");
                $("body").removeClass("opened");

            });

            $(window).on("keyup", function(event) {

                if ($("body").hasClass("opened")) {
                    switch (event.keyCode) {
                        case 27: //esc
                            menuWrapper.removeClass("opened");
                            $("body").removeClass("opened");
                            break;
                    }
                }

            });

        },
        is_touch_device: function() {
            return (("ontouchstart" in window) || (navigator.MaxTouchPoints > 0) || (navigator.msMaxTouchPoints > 0));
        }

    };

$(document).on("ready", function() {

    App.init();
    App.mobMenu($(".site-header"));

});


$(window).on("load", function() {

    App.loaded();
    App.mobMenu($(".site-header"));

});
